ul {
    padding-left: 20px; /* Indent for lists */
    list-style-type: disc; /* Default bullet points */
}

ol {
    padding-left: 20px;
    list-style-type: decimal; /* Default numbered list */
}

li {
    margin-bottom: 5px; /* Spacing between list items */
}
